import { Auth, CognitoUser } from "@aws-amplify/auth";
import { captureException, startSpan } from "@sentry/react";

import {
    EntryDTO,
    FilterFieldDTO,
    GameEntityDTO,
    ICommentResponse,
    ICommentsResponse,
    ICompetitionResponse,
    ICompetitionsResponse,
    ICreateCommentRequest,
    ICreateMonsterStatsRequest,
    ICreateNPCRequest,
    IDeleteImageRequest,
    IEntryResponse,
    IGenerateImageResponse,
    IImageResponse,
    IImagesResponse,
    ILeaderboardDTO,
    ImageSearchQueryDTO,
    IModelDataResponse,
    IMonsterStatsResponse,
    INotificationResponse,
    INotificationsResponse,
    INPCStatsResponse,
    IPollGenerateImage,
    IPresetDTO,
    IRecentImageResponse,
    IResponse,
    ISessionCostResponse,
    ISessionResponse,
    ISessionsResponse,
    IUpdateUserRequest,
    IUserChangeLogViewResponse,
    IUserResponse,
    IVisibilityRequest,
    IVoteRequest,
} from "types";
import { AWS_URL } from "utils/urlConstants";

const handleResponseStatusAndContentType = async (response: Response) => {
    const contentType = response.headers.get("content-type")!;

    if (response.status === 401) throw new Error("Request was not authorized.");

    if (contentType === null) return Promise.resolve(null);
    else if (contentType.startsWith("application/json")) return response.json();
    else if (contentType.startsWith("text/plain")) return response.text();
    else throw new Error(`Unsupported response content-type: ${contentType}`);
};

const getAccessToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

const refreshJWT = async () => {
    try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        user.refreshSession(session.getRefreshToken(), () => {});
        return true;
    } catch (e) {
        captureException(e);
        return false;
    }
};

const doPut = async (
    path: string,
    isAuth: boolean,
    formData?: FormData
): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {};

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "PUT",
        headers,
        body: formData,
    });

    const status = response.status;

    switch (status) {
        case 200:
        case 204:
            const data = await handleResponseStatusAndContentType(response);
            return { data };
        case 401:
            if (await refreshJWT()) {
                return await doPut(path, isAuth, formData);
            } else {
                const err = await response.json();
                captureException(err);
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const doPutJson = async (
    path: string,
    isAuth: boolean,
    dataPayload: object
): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {};

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    headers["Content-Type"] = "application/json";

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(dataPayload),
    });

    const status = response.status;

    switch (status) {
        case 200:
        case 204:
            const data = await handleResponseStatusAndContentType(response);
            return { data };
        case 401:
            if (await refreshJWT()) {
                return await doPutJson(path, isAuth, dataPayload);
            } else {
                const err = await response.json();
                captureException(err);
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const doPost = async (
    path: string,
    isAuth: boolean,
    formData?: FormData
): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {};

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "POST",
        headers,
        body: formData,
    });

    const status = response.status;

    switch (status) {
        case 200:
            const data = await handleResponseStatusAndContentType(response);
            return { data };
        case 201:
            return { data: true };
        case 401:
            if (await refreshJWT()) {
                return await doPost(path, isAuth, formData);
            } else {
                const err = await response.json();
                captureException(err);
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const doPostJson = async (
    path: string,
    isAuth: boolean,
    dataPayload: object
): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {};

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    headers["Content-Type"] = "application/json";

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "POST",
        headers,
        body: JSON.stringify(dataPayload),
    });

    const status = response.status;

    switch (status) {
        case 200:
            const data = await handleResponseStatusAndContentType(response);
            return { data };
        case 201:
            return { data: true };
        case 202:
            return { data: true };
        case 401:
            if (await refreshJWT()) {
                return await doPostJson(path, isAuth, dataPayload);
            } else {
                const err = await response.json();
                captureException(err);
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const doPostJsonOptAuth = async (
    path: string,
    dataPayload: object
): Promise<IResponse> => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const session = user.signInUserSession;
        if (session) {
            return doPostJson(path, true, dataPayload);
        }
        return doPostJson(path, false, dataPayload);
    } catch (error) {
        return doPostJson(path, false, dataPayload);
    }
};

const doGetOptAuth = async (path: string): Promise<IResponse> => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const session = user.signInUserSession;
        if (session) {
            return doGet(path, true);
        }
        return doGet(path, false);
    } catch (error) {
        return doGet(path, false);
    }
};

const doGet = async (path: string, isAuth: boolean): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {};

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "GET",
        headers,
    });

    const status = response.status;

    switch (status) {
        case 200:
            const data = await handleResponseStatusAndContentType(response);
            return { data };
        case 202:
            return {};
        case 401:
            if (await refreshJWT()) {
                return await doGet(path, isAuth);
            } else {
                const err = await response.json();
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const doDelete = async (path: string, isAuth: boolean): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {};

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "DELETE",
        headers,
    });

    const status = response.status;

    switch (status) {
        case 200:
            return {};
        case 202:
            return {};
        case 204:
            return {};
        case 401:
            if (await refreshJWT()) {
                return await doGet(path, isAuth);
            } else {
                const err = await response.json();
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const doDeleteWithJson = async (
    path: string,
    isAuth: boolean,
    dataPayload: IDeleteImageRequest
): Promise<IResponse> => {
    let authToken: string | undefined;
    const headers: HeadersInit = {
        "Content-Type": "application/json",
    };

    if (isAuth) {
        authToken = await getAccessToken();

        if (!authToken) {
            return { error: "User is not logged in." };
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const response = await fetch(`${AWS_URL}${path}`, {
        method: "DELETE",
        body: JSON.stringify(dataPayload),
        headers,
    });

    const status = response.status;

    switch (status) {
        case 200:
            return {};
        case 202:
            return {};
        case 401:
            if (await refreshJWT()) {
                return await doGet(path, isAuth);
            } else {
                const err = await response.json();
                return { error: err };
            }
        default:
            const err = await response.json();
            return { error: err };
    }
};

const wait = (ms: number = 1000) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
};

export const poll = async (
    fn: any,
    fnCondition: (e: any) => boolean,
    ms: number
) => {
    let result = await fn();

    while (fnCondition(result)) {
        await wait(ms);
        result = await fn();
    }

    return result;
};

export const pollGenerateImage = async (
    requestId: string,
    isPremium: boolean = false
): Promise<IPollGenerateImage> => {
    try {
        const image = isPremium
            ? `/v2/image/premium/generate/${requestId}/poll`
            : `/v2/image/generate/${requestId}/poll`;

        const { data, error } = await startSpan(
            { name: "pollGenerateImage" },
            () => {
                return doGet(image, true);
            }
        );

        if (error) {
            captureException(error);
            return { error: "Failed to get image." };
        }

        return {
            image: data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get image." };
    }
};

export const generateImage = async (
    formData: FormData,
    isPremium: boolean = false
): Promise<IGenerateImageResponse> => {
    const url = isPremium ? "/v2/image/premium/generate" : "/v2/image/generate";

    try {
        const { data, error } = await startSpan(
            { name: "generateImage" },
            () => {
                return doPost(url, true, formData);
            }
        );

        if (error) {
            captureException(error);
            return { error: "Failed to create image." };
        }

        return { requestId: data };
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to create image." };
    }
};

export const generateNpcImage = async (
    dataPayload: object,
    isPremium: boolean = false
): Promise<IGenerateImageResponse> => {
    const url = isPremium
        ? "/v1/npc/image/premium/generate"
        : "/v1/npc/image/generate";

    try {
        const { data, error } = await startSpan(
            { name: "generateNpcImage" },
            () => {
                return doPostJson(url, true, dataPayload);
            }
        );

        if (error) {
            captureException(error);
            return { error: "Failed to create image." };
        }

        return { requestId: data };
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to create image." };
    }
};

export const pollGenerateNpcImage = async (
    requestId: string,
    isPremium: boolean = false
): Promise<IPollGenerateImage> => {
    try {
        const image = isPremium
            ? `/v1/npc/image/premium/generate/${requestId}/poll`
            : `/v1/npc/image/generate/${requestId}/poll`;

        const { data, error } = await startSpan(
            { name: "pollGenerateNpcImage" },
            () => {
                return doGet(image, true);
            }
        );

        if (error) {
            captureException(error);
            return { error: "Failed to get image." };
        }

        return {
            image: data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get image." };
    }
};

export const editImage = async (
    formData: FormData
): Promise<IGenerateImageResponse> => {
    const url = "/v2/image/edit";

    try {
        const { data, error } = await startSpan({ name: "editImage" }, () => {
            return doPost(url, true, formData);
        });

        if (error) {
            console.error(error);
            return { error: "Failed to create image." };
        }

        return { requestId: data };
    } catch (e) {
        console.error(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to create image." };
    }
};

export const pollEditImage = async (
    requestId: string
): Promise<IPollGenerateImage> => {
    try {
        const image = `/v1/npc/image/generate/${requestId}/poll`;

        const { data, error } = await startSpan(
            { name: "pollEditImage" },
            () => {
                return doGet(image, true);
            }
        );

        if (error) {
            console.error(error);
            return { error: "Failed to get image." };
        }

        return {
            image: data,
        };
    } catch (e) {
        console.error(e);
        return { error: "Failed to get image." };
    }
};

export const generateMonsterImage = async (
    dataPayload: object,
    isPremium: boolean = false
): Promise<IGenerateImageResponse> => {
    const url = isPremium
        ? "/v1/monster/image/premium/generate"
        : "/v1/monster/image/generate";

    try {
        const { data, error } = await startSpan(
            { name: "generateMonsterImage" },
            () => {
                return doPostJson(url, true, dataPayload);
            }
        );

        if (error) {
            console.error(error);
            return { error: "Failed to create image." };
        }

        return { requestId: data };
    } catch (e) {
        console.error(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to create image." };
    }
};

export const pollGenerateMonsterImage = async (
    requestId: string,
    isPremium: boolean = false
): Promise<IPollGenerateImage> => {
    try {
        const image = isPremium
            ? `/v1/monster/image/premium/generate/${requestId}/poll`
            : `/v1/monster/image/generate/${requestId}/poll`;

        const { data, error } = await startSpan(
            { name: "pollGenerateMonsterImage" },
            () => {
                return doGet(image, true);
            }
        );

        if (error) {
            console.error(error);
            return { error: "Failed to get image." };
        }

        return {
            image: data,
        };
    } catch (e) {
        console.error(e);
        return { error: "Failed to get image." };
    }
};

export const registerUser = async (): Promise<boolean> => {
    try {
        const { data, error } = await startSpan(
            { name: "registerUser" },
            () => {
                return doPost("/user/register", true);
            }
        );

        if (error) {
            captureException(error);
            return false;
        }

        return data as boolean;
    } catch (e) {
        captureException(e);
        return false;
    }
};

export const getUserInfo = async (): Promise<IUserResponse> => {
    try {
        const { data, error } = await startSpan({ name: "getUserInfo" }, () => {
            return doGet("/user", true);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to get user" };
        }

        return {
            user: data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get user" };
    }
};

export const getThemes = async (): Promise<any> => {
    try {
        const { data, error } = await startSpan({ name: "getThemes" }, () => {
            return doGet("/theme/all", false);
        });

        if (error) {
            captureException(error);
            return { error };
        }

        return {
            themes: data || [],
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get themes" };
    }
};

export const getFields = async (): Promise<any> => {
    try {
        const { data, error } = await startSpan({ name: "getFields" }, () => {
            return doGet("/v1/npc/fields", false);
        });

        if (error) {
            captureException(error);
            return { error };
        }

        return {
            fields: data || [],
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get fields" };
    }
};

export const getFieldsForEntity = async (entityName: string): Promise<any> => {
    try {
        const { data, error } = await startSpan({ name: "getFields" }, () => {
            return doGet(`/theme/${entityName}`, false);
        });

        if (error) {
            captureException(error);
            return { error };
        }

        return {
            fields: data || [],
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get fields" };
    }
};

export const getMonsterFields = async (): Promise<any> => {
    try {
        const { data, error } = await startSpan(
            { name: "getMonsterFields" },
            () => {
                return doGet("/v1/monster/fields", false);
            }
        );

        if (error) {
            console.error(error);
            return { error };
        }

        return {
            fields: data || [],
        };
    } catch (e) {
        console.error(e);
        return { error: "Failed to get fields" };
    }
};

export const getMostRecentPublicImages = async (
    cursor: Date = new Date(),
    size: number
): Promise<IRecentImageResponse> => {
    try {
        const cursorIsoString =
            cursor instanceof Date ? cursor.toISOString() : cursor;

        const url = `/v1/images/public?cursor=${cursorIsoString}&size=${size.toString()}`;
        const { data, error } = await startSpan(
            { name: "getMostRecentPublicImages" },
            () => {
                return doGet(url, false);
            }
        );

        if (error) {
            captureException(error);
            return { error };
        }

        return {
            image: data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get fields" };
    }
};

export const onToggleVisibility = async (
    imageId: string,
    isVisible: boolean = false
): Promise<{ error?: string }> => {
    const url = `/v1/images/${imageId}/visibility`;
    const payload: IVisibilityRequest = {
        isVisible: isVisible,
    };

    try {
        const { error } = await startSpan(
            { name: "onToggleVisibility" },
            () => {
                return doPostJson(url, true, payload);
            }
        );

        if (error) {
            captureException(error);
            return { error: "Failed to set image visibility." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to set image visibility." };
    }
};

export const voteOnImage = async (
    imageId: string,
    isUpvote: boolean = false
): Promise<{ error?: string }> => {
    const url = `/v1/images/${imageId}/vote`;
    const payload: IVoteRequest = {
        isUpvote: isUpvote,
    };
    try {
        const { error } = await startSpan({ name: "voteOnImage" }, () => {
            return doPostJson(url, true, payload);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to vote on image." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to vote on image." };
    }
};

export const deleteImage = async (
    imageId: string
): Promise<{ error?: string }> => {
    const url = `/v1/images/${imageId}`;
    try {
        const { error } = await startSpan({ name: "deleteImage" }, () => {
            return doDelete(url, true);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to delete image." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to delete image." };
    }
};

export const deleteAllImages = async (
    dataPayload: IDeleteImageRequest
): Promise<{ error?: string }> => {
    const url = "/v1/images/delete/all";
    try {
        const { error } = await startSpan({ name: "deleteImage" }, () => {
            return doDeleteWithJson(url, true, dataPayload);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to delete image." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to delete image." };
    }
};

export const checkUsernameAvailability = async (
    username: string
): Promise<boolean> => {
    try {
        const url = `/user/username/${username}/validate`;
        const { data, error } = await startSpan(
            { name: "checkUsernameAvailability" },
            () => {
                return doGetOptAuth(url);
            }
        );

        if (error) {
            captureException(error);
            return false;
        }

        return data;
    } catch (e) {
        captureException(e);
        return false;
    }
};

export const updateUser = async (
    userUpdate: IUpdateUserRequest
): Promise<{ error?: string }> => {
    const url = "/user";
    const payload: IUpdateUserRequest = {
        username: userUpdate.username,
        marketingConsent: userUpdate.marketingConsent,
    };
    try {
        const { error } = await startSpan({ name: "updateUser" }, () => {
            return doPostJson(url, true, payload);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to update user." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to update user." };
    }
};

export const searchImages = async (
    params: ImageSearchQueryDTO
): Promise<IImagesResponse> => {
    try {
        const response = await startSpan({ name: "searchImages" }, async () => {
            return doPostJsonOptAuth("/api/v1/search/images", params);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            image: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const getImage = async (imageId: string): Promise<IImageResponse> => {
    try {
        const response = await startSpan({ name: "getImage" }, async () => {
            return doGetOptAuth(`/v1/images/${imageId}`);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            image: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const getFilterFields = async (): Promise<FilterFieldDTO[]> => {
    try {
        const url = "/api/v1/search/filters";
        const { data, error } = await startSpan(
            { name: "getFilterFields" },
            () => {
                return doGet(url, false);
            }
        );

        if (error) {
            captureException(error);
            return [];
        }

        return data;
    } catch (e) {
        captureException(e);
        return [];
    }
};

export const getModelConfig = async (): Promise<IModelDataResponse> => {
    try {
        const url = "/api/v1/models/configurations";
        const { data, error } = await startSpan(
            { name: "getModelConfigs" },
            () => {
                return doGet(url, false);
            }
        );

        if (error) {
            captureException(error);
            return {
                modelConfigs: [],
                samplers: [],
                models: [],
            };
        }

        return data;
    } catch (e) {
        captureException(e);
        return {
            modelConfigs: [],
            samplers: [],
            models: [],
        };
    }
};

export const getLeaderboards = async (
    month?: string,
    year?: string
): Promise<ILeaderboardDTO[]> => {
    try {
        let url = "/api/v1/leaderboards";

        if (month !== "currentMonth" || year !== "currentYear") {
            if (month && year) {
                const formattedMonth = `${year}-${String(month).padStart(
                    2,
                    "0"
                )}`;
                url += `?month=${formattedMonth}`;
            }
        }

        const { data, error } = await startSpan(
            { name: "getLeaderboards" },
            () => {
                return doGet(url, false);
            }
        );

        if (error) {
            captureException(error);
            return [];
        }

        return data;
    } catch (e) {
        captureException(e);
        return [];
    }
};

export const savePreset = async (
    preset: IPresetDTO
): Promise<IPresetDTO | { error: string }> => {
    const url = "/api/v1/presets";
    const formData = new FormData();
    formData.append("prompt", preset.prompt);
    formData.append("artStyle", preset.artStyle);
    formData.append("theme", preset.theme);
    formData.append("name", preset.name);
    formData.append("premium", preset.premium.toString());

    if (preset.freeGenModelDTO) {
        formData.append(
            "freeGenModelDTO",
            JSON.stringify(preset.freeGenModelDTO)
        );
    }

    Object.entries(preset.requestFields).forEach(([key, value]) => {
        formData.append(`requestFields[${key}]`, value);
    });
    if (preset.file) {
        formData.append("file", preset.file);
    }
    formData.append("presetType", preset.presetType);
    if (preset.additionalField) {
        formData.append("additionalField", preset.additionalField);
    }

    try {
        const { data, error } = await doPost(url, true, formData);
        if (error) {
            captureException(error);
            return { error: "Failed to save preset." };
        }
        return data as IPresetDTO;
    } catch (e) {
        captureException(e);
        return { error: "Failed to save preset." };
    }
};

export const getPreset = async (
    id: string
): Promise<IPresetDTO | { error: string }> => {
    const url = `/api/v1/presets/${id}`;

    try {
        const { data, error } = await doGet(url, true);
        if (error) {
            captureException(error);
            return { error: "Failed to get preset." };
        }
        return data as IPresetDTO;
    } catch (e) {
        captureException(e);
        return { error: "Failed to get preset." };
    }
};

export const updatePreset = async (
    id: string,
    preset: IPresetDTO
): Promise<IPresetDTO | { error: string }> => {
    const url = `/api/v1/presets/${id}`;
    const formData = new FormData();
    formData.append("prompt", preset.prompt);
    formData.append("artStyle", preset.artStyle);
    formData.append("theme", preset.theme);
    formData.append("name", preset.name);
    formData.append("premium", preset.premium.toString());

    if (preset.freeGenModelDTO) {
        formData.append(
            "freeGenModelDTO",
            JSON.stringify(preset.freeGenModelDTO)
        );
    }

    Object.entries(preset.requestFields).forEach(([key, value]) => {
        formData.append(`requestFields[${key}]`, value);
    });
    if (preset.file) {
        formData.append("file", preset.file);
    }
    formData.append("presetType", preset.presetType);
    if (preset.additionalField) {
        formData.append("additionalField", preset.additionalField);
    }

    try {
        const { data, error } = await doPut(url, true, formData);
        if (error) {
            captureException(error);
            return { error: "Failed to update preset." };
        }
        return data as IPresetDTO;
    } catch (e) {
        captureException(e);
        return { error: "Failed to update preset." };
    }
};

export const listPresets = async (
    presetType?: string
): Promise<IPresetDTO[] | { error: string }> => {
    const url = presetType
        ? `/api/v1/presets?presetType=${presetType}`
        : "/api/v1/presets";

    try {
        const { data, error } = await doGet(url, true);
        if (error) {
            captureException(error);
            return { error: "Failed to list presets." };
        }
        return data as IPresetDTO[];
    } catch (e) {
        captureException(e);
        return { error: "Failed to list presets." };
    }
};

export const deletePreset = async (
    presetId: string
): Promise<{ error?: string }> => {
    const url = `/api/v1/presets/${presetId}`;
    try {
        const { error } = await startSpan({ name: "deletePreset" }, () => {
            return doDelete(url, true);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to delete preset." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to delete preset." };
    }
};

export const getCompetition = async (
    competitionId: string
): Promise<ICompetitionResponse> => {
    const url = `/api/competitions/${competitionId}`;
    try {
        const response = await startSpan(
            { name: "getCompetition" },
            async () => {
                return doGetOptAuth(url);
            }
        );
        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }
        return {
            competition: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get competition." };
    }
};

export const getActiveCompetitions =
    async (): Promise<ICompetitionsResponse> => {
        const url = "/api/competitions/active";
        try {
            const response = await startSpan(
                { name: "getArchivedCompetitions" },
                async () => {
                    return doGetOptAuth(url);
                }
            );
            if (response.error) {
                captureException(response.error);
                return { error: response.error };
            }
            return {
                competition: response.data,
            };
        } catch (e) {
            captureException(e);
            return { error: "Failed to get active competitions." };
        }
    };

export const getArchivedCompetitions =
    async (): Promise<ICompetitionsResponse> => {
        const url = "/api/competitions/archived";
        try {
            const response = await startSpan(
                { name: "getArchivedCompetitions" },
                async () => {
                    return doGetOptAuth(url);
                }
            );
            if (response.error) {
                captureException(response.error);
                return { error: response.error };
            }
            return {
                competition: response.data,
            };
        } catch (e) {
            captureException(e);
            return { error: "Failed to get archived competitions." };
        }
    };

export const getEntryByUserAndCompetition = async (
    competitionId: string
): Promise<IEntryResponse | null> => {
    const url = `/api/entries/competition/${competitionId}`;
    try {
        const response = await startSpan(
            { name: "getEntryByUserAndCompetition" },
            async () => {
                return doGet(url, true);
            }
        );
        if (response.error) {
            if (response.error.status === 404) {
                return null;
            } else {
                captureException(response.error);
                return { error: response.error };
            }
        }
        return {
            entry: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get entry by user and competition." };
    }
};

export const getEligibleCompetitions = async (
    imageId: string
): Promise<ICompetitionsResponse> => {
    const url = `/api/eligibility/image/${imageId}`;
    try {
        const response = await startSpan(
            { name: "getEligibleCompetitions" },
            async () => {
                return doGet(url, true);
            }
        );
        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }
        return {
            competition: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get eligible competitions." };
    }
};

export const submitEntry = async (
    imageId: string,
    competitionId: string
): Promise<IEntryResponse> => {
    try {
        const entry: EntryDTO = {
            id: "",
            competitionId: competitionId,
            imageId: imageId,
        };
        const response = await startSpan({ name: "submitEntry" }, async () => {
            return doPostJson("/api/entries", true, entry);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return response.data;
    } catch (e) {
        captureException(e);
        return { error: "Failed to submit entry" };
    }
};

export const deleteEntry = async (
    entryId: string
): Promise<{ error?: string }> => {
    const url = `/api/entries/${entryId}`;
    try {
        const { error } = await startSpan({ name: "deleteEntry" }, () => {
            return doDelete(url, true);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to delete entry." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to delete entry." };
    }
};

export const getUnseenChangelogs =
    async (): Promise<IUserChangeLogViewResponse> => {
        const url = "/api/user-changelog-views/unseen";
        try {
            const response = await startSpan(
                { name: "getUnseenChangelogs" },
                async () => {
                    return doGet(url, true);
                }
            );
            if (response.error) {
                captureException(response.error);
                return { error: response.error };
            }
            return {
                unseenChangelogs: response.data,
            };
        } catch (e) {
            captureException(e);
            return { error: "Failed to get changelogs." };
        }
    };

export const markChangelogSeen = async (
    changelogId: string
): Promise<{ error?: string }> => {
    const url = `/api/user-changelog-views/${changelogId}/seen`;

    try {
        const { error } = await startSpan({ name: "markChangelogSeen" }, () => {
            return doPostJson(url, true, {});
        });

        if (error) {
            captureException(error);
            return { error: "Failed to mark changelog as seen." };
        }

        return {};
    } catch (e) {
        captureException(e);
        if (e === "User is not logged in.") {
            return { error: e };
        }
        return { error: "Failed to mark changelog as seen." };
    }
};

export const createComment = async (
    commentRequest: ICreateCommentRequest
): Promise<ICommentResponse> => {
    try {
        const response = await startSpan(
            { name: "createComment" },
            async () => {
                return doPostJson("/api/comments", true, commentRequest);
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { comment: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to create comment." };
    }
};

export const getCommentsByImage = async (
    imageId: string,
    sort: string = "top"
): Promise<ICommentsResponse> => {
    const url = `/api/comments/image/${imageId}?sort=${sort}`;
    try {
        const response = await startSpan(
            { name: "getCommentsByImage" },
            async () => {
                return doGetOptAuth(url);
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { comments: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to retrieve comments." };
    }
};

export const getReplies = async (
    parentCommentId: string,
    page: number,
    limit: number
): Promise<ICommentsResponse> => {
    const url = `/api/comments/${parentCommentId}/replies?page=${page}&size=${limit}`;
    try {
        const response = await startSpan({ name: "getReplies" }, async () => {
            return doGetOptAuth(url);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { comments: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to retrieve replies." };
    }
};

export const likeComment = async (
    commentId: string
): Promise<ICommentResponse> => {
    const url = `/api/comments/${commentId}/like`;
    try {
        const response = await startSpan({ name: "likeComment" }, async () => {
            return doPostJson(url, true, {});
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { comment: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to like comment." };
    }
};

export const getLikedComments = async (
    imageId: string
): Promise<ICommentsResponse> => {
    const url = `/api/comments/likes/image/${imageId}`;
    try {
        const response = await startSpan(
            { name: "getLikedComments" },
            async () => {
                return doGet(url, true);
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { comments: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to retrieve liked comments." };
    }
};

export const deleteComment = async (
    commentId: string
): Promise<{ error?: string }> => {
    const url = `/api/comments/${commentId}`;
    try {
        const { error } = await startSpan({ name: "deleteComment" }, () => {
            return doDelete(url, true);
        });

        if (error) {
            captureException(error);
            return { error: "Failed to delete comment." };
        }

        return {};
    } catch (e) {
        captureException(e);
        return { error: "Failed to delete comment." };
    }
};

export const updateComment = async (
    commentId: string,
    newContent: string
): Promise<ICommentResponse> => {
    const url = `/api/comments/${commentId}`;
    try {
        const response = await startSpan(
            { name: "updateComment" },
            async () => {
                return doPutJson(url, true, { content: newContent });
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { comment: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to update comment." };
    }
};

export const getUnreadNotifications =
    async (): Promise<INotificationsResponse> => {
        const url = "/api/notifications";
        try {
            const response = await startSpan(
                { name: "getUnreadNotifications" },
                async () => {
                    return doGet(url, true);
                }
            );

            if (response.error) {
                captureException(response.error);
                return { error: response.error };
            }

            return { notifications: response.data };
        } catch (e) {
            captureException(e);
            return { error: "Failed to retrieve unread notifications." };
        }
    };

export const markNotificationAsRead = async (
    notificationId: string
): Promise<INotificationResponse> => {
    const url = `/api/notifications/${notificationId}/read`;
    try {
        const response = await startSpan(
            { name: "markNotificationAsRead" },
            async () => {
                return doPostJson(url, true, {});
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { notification: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to mark notification as read." };
    }
};

export const markAllNotificationsAsRead =
    async (): Promise<INotificationsResponse> => {
        const url = "/api/notifications/read/all";
        try {
            const response = await startSpan(
                { name: "markAllNotificationsAsRead" },
                async () => {
                    return doPostJson(url, true, {});
                }
            );

            if (response.error) {
                captureException(response.error);
                return { error: response.error };
            }

            return { notifications: response.data };
        } catch (e) {
            captureException(e);
            return { error: "Failed to mark all notifications as read." };
        }
    };

export const getMonsterStatsByImageId = async (
    imageId: string
): Promise<GameEntityDTO | null> => {
    const url = `/api/stats/monsters/${imageId}`;
    try {
        const response = await startSpan(
            { name: "getMonsterStatsByImageId" },
            async () => {
                return doGet(url, false);
            }
        );
        if (response.error) {
            if (response.error.status === 404) {
                return null;
            } else {
                captureException(response.error);
                return null;
            }
        }
        return response.data as GameEntityDTO;
    } catch (e) {
        captureException(e);
        return null;
    }
};

export const createMonsterStats = async (
    statsRequest: ICreateMonsterStatsRequest
): Promise<IMonsterStatsResponse> => {
    try {
        const response = await startSpan(
            { name: "createMonsterStats" },
            async () => {
                return doPostJson("/api/stats/monsters", true, statsRequest);
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { taskId: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to create monster stats." };
    }
};

export const getStatus = async (taskId: string): Promise<boolean | null> => {
    const url = `/api/stats/monsters/${taskId}/status`;
    try {
        const response = await startSpan({ name: "getStatus" }, async () => {
            return doGet(url, true);
        });
        if (response.error) {
            if (response.error.status === 404) {
                return null;
            } else {
                captureException(response.error);
                return null;
            }
        }
        return response.data;
    } catch (e) {
        captureException(e);
        return null;
    }
};

export const deleteEntityById = async (
    entityType: string,
    id: string
): Promise<void> => {
    const url = `/api/stats/entities/${entityType}/${id}`;
    try {
        const response = await startSpan(
            { name: "deleteEntityById" },
            async () => {
                return doDelete(url, true);
            }
        );
        if (response.error) {
            throw new Error(response.error);
        }
    } catch (e) {
        captureException(e);
        throw new Error(`Failed to delete ${entityType} stats.`);
    }
};

export const getNPCStatsByImageId = async (
    imageId: string
): Promise<GameEntityDTO | null> => {
    const url = `/api/stats/npcs/${imageId}`;
    try {
        const response = await startSpan(
            { name: "getNPCStatsByImageId" },
            async () => {
                return doGet(url, false);
            }
        );
        if (response.error) {
            if (response.error.status === 404) {
                return null;
            } else {
                captureException(response.error);
                return null;
            }
        }
        return response.data as GameEntityDTO;
    } catch (e) {
        captureException(e);
        return null;
    }
};

export const createNpcStats = async (
    statsRequest: ICreateNPCRequest
): Promise<INPCStatsResponse> => {
    try {
        const response = await startSpan(
            { name: "createNPCStats" },
            async () => {
                return doPostJson("/api/stats/npcs", true, statsRequest);
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return { taskId: response.data };
    } catch (e) {
        captureException(e);
        return { error: "Failed to create monster stats." };
    }
};

export const getNpcStatus = async (taskId: string): Promise<boolean | null> => {
    const url = `/api/stats/npcs/${taskId}/status`;
    try {
        const response = await startSpan({ name: "getStatus" }, async () => {
            return doGet(url, true);
        });
        if (response.error) {
            if (response.error.status === 404) {
                return null;
            } else {
                captureException(response.error);
                return null;
            }
        }
        return response.data;
    } catch (e) {
        captureException(e);
        return null;
    }
};

export const streamAudioData = async (
    audioBlob: Blob,
    transcriptionName: string
): Promise<ISessionCostResponse> => {
    const formData = new FormData();
    formData.append("audio", audioBlob, "audio.webm");
    formData.append("name", transcriptionName);
    try {
        const response = await startSpan({ name: "getStatus" }, async () => {
            return await doPost("/api/session/stream", true, formData);
        });

        if (response.error === "Payment Required") {
            return { error: "Payment Required" }; // Return the error if 402 happens
        }

        if (response.error) {
            if (response.error.status === 404) {
                return response.error;
            } else {
                captureException(response.error);
                return response.error;
            }
        }
        return response.data;
    } catch (e) {
        console.log("Captured error: ", e);
        captureException(e);
        return {
            error: "An error occurred while streaming audio data.",
        } as ISessionCostResponse;
    }
};

export const getSessionImages = async (
    transcriptionName: string
): Promise<IImagesResponse> => {
    try {
        const response = await startSpan({ name: "searchImages" }, async () => {
            return await doGet(
                `/api/session/${transcriptionName}/images`,
                true
            );
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            image: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const getSessionImagesById = async (
    sessionId: string
): Promise<IImagesResponse> => {
    try {
        const response = await startSpan({ name: "searchImages" }, async () => {
            return await doGetOptAuth(`/api/session/id/${sessionId}/images`);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            image: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const getSessions = async (): Promise<ISessionsResponse> => {
    try {
        const response = await startSpan({ name: "searchImages" }, async () => {
            return await doGet("/api/session/sessions", true);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            sessionNames: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const getSession = async (
    sessionId: string
): Promise<ISessionResponse> => {
    try {
        const response = await startSpan({ name: "searchImages" }, async () => {
            return await doGetOptAuth(`/api/session/${sessionId}`);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            session: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const getSessionByName = async (
    sessionName: string
): Promise<ISessionResponse> => {
    try {
        const response = await startSpan({ name: "searchImages" }, async () => {
            return await doGet(`/api/session/name/${sessionName}`, true);
        });

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            session: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to get images" };
    }
};

export const createSession = async (
    sessionName: string
): Promise<ISessionResponse> => {
    try {
        const formData = new FormData();
        formData.append("sessionName", sessionName);
        const response = await startSpan(
            { name: "createSession" },
            async () => {
                return await doPost("/api/session", true, formData);
            }
        );

        if (response.error) {
            captureException(response.error);
            return { error: response.error };
        }

        return {
            session: response.data,
        };
    } catch (e) {
        captureException(e);
        return { error: "Failed to create session" };
    }
};
