export type IPromptOptions = {
    shortName: string;
    fullName: string;
    premium: boolean;
};

export interface IFieldOptions {
    npcFieldsMap: {
        [key: string]: string[];
    };
}

export interface FilterFieldDTO {
    label: string;
    values: string[];
    isFreeText: boolean;
    isCheckbox: boolean;
}

export interface ImageSearchQueryDTO {
    query?: string;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, string>;
    page?: number;
    pageSize?: number;
    userGallery?: boolean;
    createdAfter?: string | null;
    createdBefore?: string | null;
}

export interface ImageDTO {
    id: string;
    image: string;
    isPublic: boolean;
    voteCount: number;
    createdAt: Date;
    isVotedByUser: boolean;
    imageMetadata: ImageMetadata;
    parentImage?: ImageDTO;
    childImages?: ImageDTO[];
}

export interface ImageMetadata {
    npcFieldsMap: {
        [key: string]: string;
    };
    theme: string;
    premium: boolean;
    username: string;
    description: string;
    modelData?: IFreeGenModelDTO;
    requestType: RequestType;
}

export interface Option {
    value: string;
    label: string;
    isDisabled?: boolean;
    __isNew__?: boolean;
}

export interface GroupedOption {
    label: string;
    options: Option[];
}

export enum AppReducerActionType {
    SHOW_PRICING_TABLE,
    SHOW_SUBSCRIPTION_TABLE,
    DEC_GOLD,
    SET_GOLD,
    SET_COOKIE_CONSENT,
    SET_LOGGED_IN,
    SHOW_AUTH_MODAL,
    SET_USER_PREMIUM_LEVEL,
    SET_USER_FREE_GENS,
    TOGGLE_OVERLAY_VISIBILITY,
    SET_USERNAME,
    SET_EMAIL,
    SET_REFERRAL_CODE,
    SET_GCLID,
}

export enum UserPremiumType {
    NONE = "NONE",
    BASIC = "BASIC",
    PLUS = "PLUS",
    PRO = "PRO",
    ELITE = "ELITE",
    ULTIMATE = "ULTIMATE",
}

export enum EditType {
    IMAGE = "IMAGE",
    ADETAILER = "ADETAILER",
    BGREMOVAL = "BGREMOVAL",
    FACESWAP = "FACESWAP",
    INPAINTING = "INPAINTING",
    UPSCALER = "UPSCALER",
}

export enum RequestType {
    CHARACTER = "CHARACTER",
    NPC = "NPC",
    MONSTER = "MONSTER",
    LANDSCAPE = "LANDSCAPE",
    ITEM = "ITEM",
    BATTLEMAP = "BATTLEMAP",
}

export enum PresetType {
    CHARACTER = "CHARACTER",
    MONSTER = "MONSTER",
    NPC = "NPC",
    ITEM = "ITEM",
    LANDSCAPE = "LANDSCAPE",
    BATTLEMAP = "BATTLEMAP",
}

export interface AppReducerAction {
    type: AppReducerActionType;
    payload: AppReducerState;
}

export interface AppReducerState {
    showPricingTable: boolean;
    showSubscriptionTable: boolean;
    gold: number;
    cookieConsentGiven: boolean;
    isLoggedIn: boolean;
    showAuthModal: boolean;
    userPremiumLevel: UserPremiumType;
    userFreeGens: number;
    isOverlayVisible?: boolean;
    username: string;
    email: string;
    referralCode: string;
    gclid: string;
}

export type IResponse = {
    data?: any;
    error?: any;
};

export type IGenerateImageResponse = {
    requestId?: string;
    error?: string;
};

export type IPollGenerateImage = {
    image?: ImageDTO[];
    error?: string;
};

export type ISessionsResponse = {
    sessionNames?: string[];
    error?: string;
};

export type ISessionResponse = {
    session?: SessionDTO;
    error?: string;
};

export type ISessionCostResponse = {
    sessionCost?: SessionResponseDTO;
    error?: string;
};

export type IRecentImageResponse = {
    image?: ImageDTO[];
    error?: string;
};

export type IImagesResponse = {
    image?: ImageDTO[];
    error?: string;
};

export type IImageResponse = {
    image?: ImageDTO;
    error?: string;
};

export type ICompetitionResponse = {
    competition?: CompetitionDTO;
    error?: string;
};

export type ICompetitionsResponse = {
    competition?: CompetitionDTO[];
    error?: string;
};

export type IEntryResponse = {
    entry?: EntryDTO;
    error?: string;
};

export type IUserResponse = {
    user?: IUserDTO;
    error?: string;
};

export type IUserChangeLogViewResponse = {
    unseenChangelogs?: UserChangelogViewDTO[];
    error?: string;
};

export type IUserDTO = {
    email?: string;
    gold?: number;
    subscriptionType?: UserPremiumType;
    requestsInLastDay?: number;
    username: string;
    marketingConsent?: boolean;
    referralCode?: string;
    hasUnseenChangelogs?: boolean;
    userId?: string;
};

export type ICommentResponse = {
    comment?: ICommentDTO;
    error?: string;
};

export type ICommentsResponse = {
    comments?: ICommentDTO[];
    error?: string;
};

export type ICreateCommentRequest = {
    content: string;
    attachmentUrl?: string;
    parentCommentId?: string;
    imageId: string;
};

export interface ICommentDTO {
    id: string;
    content: string;
    attachmentUrl?: string;
    parentCommentId?: string;
    imageId: string;
    authorEmail: string;
    createdAt: string;
    updatedAt: string;
    likes: number;
    likedByCurrentUser: boolean;
    user: IUserDTO;
    replies: ICommentDTO[];
    edited: boolean;
    hasMoreReplies: boolean;
}

export type IVisibilityRequest = {
    isVisible?: boolean;
};

export type IVoteRequest = {
    isUpvote?: boolean;
};

export type IUpdateUserRequest = {
    username?: string;
    marketingConsent?: boolean | null;
};

export type IDeleteImageRequest = {
    imageIds: string[];
};

export interface IModelConfig {
    modelName: string;
    defaultSampler: string;
    defaultSteps: number;
    defaultCfgScale: number;
    thumbnailUrl: string;
    subscriberOnly: boolean;
}

export interface IModelDataResponse {
    modelConfigs: IModelConfig[];
    samplers: string[];
    models: string[];
}

export interface IFreeGenModelDTO {
    model: string;
    steps?: string;
    cfg?: string;
    sampler?: string;
}

export interface ILeaderboardEntryDTO {
    username: string;
    totalVoteCount: number;
}

export interface ILeaderboardDTO {
    name: string;
    entries: ILeaderboardEntryDTO[];
}

export interface IPresetDTO {
    id: string;
    name: string;
    prompt: string;
    artStyle: string;
    theme: string;
    requestFields: { [key: string]: string };
    fileUrl?: string;
    file?: File;
    presetType: string;
    additionalField?: string;
    freeGenModelDTO?: IFreeGenModelDTO;
    premium: boolean;
    isCustomConfig: boolean;
}

export interface CriteriaDTO {
    id: string;
    competitionId: string;
    criteriaFields: { [key: string]: string };
}

export interface PrizeDTO {
    id: string;
    competitionId: string;
    rank: number;
    gold: number;
    subscriptionType: string;
    badge: string;
    entryId: string;
    image?: ImageDTO;
}

export interface EntryDTO {
    id: string;
    competitionId: string;
    userId?: string;
    imageId: string;
    rank?: number;
    prizeId?: string;
}

export interface CompetitionDTO {
    id: string;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    competitionEnded: boolean;
    criteria: CriteriaDTO[];
    prizes: PrizeDTO[];
    topEntries: EntryDTO[];
}

export interface UserChangelogViewDTO {
    changelogId: string;
    changelogTitle: string;
    changelogDescription: string;
    changelogImageUrl: string;
    changelogTimestamp: string;
}

export type SiteConfig = {
    avatar?: string;
    siteUrl: string;
    siteName: string;
    siteDescription: string;
    siteThumbnail: string;
    nav: Array<{ label: string; href: string }>;
    social?: {
        github?: string;
        twitter?: string;
        linkedin?: string;
        instagram?: string;
    };
};

export type MDXFrontMatter = {
    slug: string;
    title: string;
    description?: string;
    date: string;
    tags?: Array<string>;
};

export interface INotification {
    id: string;
    type: string;
    relatedEntityId: string;
    message: string;
    isRead: boolean;
    createdAt: string;
}

export interface INotificationsResponse {
    notifications?: INotification[];
    error?: string;
}

export interface INotificationResponse {
    notification?: INotification;
    error?: string;
}

export interface GameEntityDTO {
    id: string;
    name: string;
    description: string;
    crLevel?: number;
    level?: number;
    hitPoints: number;
    armorClass: number;
    speed: string;
    size: string;
    environment: string;
    alignment: string;
    languages: string[];
    imageId: string;
    userId: string;
    entityType: string;
    [key: string]: any;
}

export interface ICreateMonsterStatsRequest {
    imageId: string;
    name: string;
    crLevel: number;
    entityType: string;
}

export interface IMonsterStatsResponse {
    taskId?: string;
    error?: any;
}

export enum GameType {
    DND5e = "DnD5e",
    PATHFINDER = "Pathfinder",
}

export interface DnD5eMonsterDTO extends GameEntityDTO {
    monsterType: string;
    skills: SkillDTO[];
    spells: SpellDTO[];
    savingThrows: SavingThrowDTO[];
    legendaryActions: LegendaryActionDTO[];
    actions: ActionDTO[];
    reactions: ActionDTO[];
    attributes: AttributeDTO[];
    equipment: EquipmentDTO[];
}

export interface DnD5eNPCDTO extends GameEntityDTO {
    npcClass: string;
    race: string;
    background: string;
    occupation: string;
    factionAffiliations: string;
    personalityTraits: string[];
    goal: string;
    secret: string;
    flaws: string[];
    fears: string[];
    wantsNeeds: string[];
    services: string[];
    rewards: string[];
    dialoguePatterns: string[];
    bodyLanguage: string;
    voice: string;
    behaviorPatterns: string[];
    majorSecret: string;
    minorSecrets: string;
    interactionStrategy: string;
    combatStrategy: string;
    attributes: AttributeDTO[];
    skills: SkillDTO[];
    equipment: EquipmentDTO[];
}

export interface ICreateNPCRequest {
    entityType: string;
    name: string;
    level: number; // This is the NPC's level
    imageId: string;
}

export interface INPCStatsResponse {
    taskId?: string; // Optional task ID if the process is asynchronous
    error?: any; // Optional error field for capturing any issues
}

export interface AttributeDTO {
    name: string;
    value: string;
    modifier: string;
}

export interface SkillDTO {
    name: string;
    bonus: string;
}

export interface SpellDTO {
    name: string;
    level: number;
    school: string;
    description: string;
}

export interface SavingThrowDTO {
    attribute: AttributeDTO;
    bonus: string;
}

export interface LegendaryActionDTO {
    name: string;
    description: string;
    cost: number;
}

export interface EquipmentDTO {
    name: string;
    type: string;
    description: string;
    value: number;
}

export interface ActionDTO {
    name: string;
    description: string;
}

export interface SessionDTO {
    id: string;
    name: string;
    username: string;
    sessionCost: SessionResponseDTO;
}

export interface SessionResponseDTO {
    totalCost: number;
    chargedGold: number;
}
