import { FC, useContext } from "react";
import Image from "next/image";
import { Dropdown } from "components/atoms/Dropdown";
import { GemCounter } from "components/atoms/GemCounter";
import Navigation from "components/atoms/Navigation";
import { AuthButton, ProfileButton, SignOutButton } from "components/molecules/AuthButton";
import { GemCounterHeader } from "components/molecules/GemCounterHeader";
import Notifications from "components/molecules/Notifications";
import { Sidebar } from "components/molecules/Sidebar";
import { AppContext } from "lib/contexts";
import { useGclid, useReferralCode } from "lib/hooks";
import { UserPremiumType } from "types";
import { Title } from "utils/styling/typography.styled";
import * as Styled from "./Header.styled";
import Icon from "./icon.png";
type HeaderProps = {
  isLoggedIn: boolean;
  fetched?: boolean;
  userPremiumLevel?: UserPremiumType;
  userEmail?: string;
  username?: string;
  gold?: number;
  isSidebar?: boolean;
};
const HeaderButtons: FC<HeaderProps> = ({
  isLoggedIn,
  userPremiumLevel,
  userEmail,
  gold,
  isSidebar
}) => {
  return isLoggedIn ? <>
            <Navigation />
            <hr />
            {gold && <GemCounter gold={gold} />}
            {!isSidebar && <>
                    <Notifications />
                    <Dropdown userPremiumLevel={userPremiumLevel} userEmail={userEmail} />
                </>}
        </> : <>
            <Navigation />
            <hr />
            <AuthButton />
        </>;
};
export const Header: FC<HeaderProps> = ({
  isLoggedIn,
  userPremiumLevel,
  userEmail,
  username
}) => {
  const {
    state
  } = useContext(AppContext);
  useReferralCode();
  useGclid();
  return <Styled.Header data-sentry-element="unknown" data-sentry-component="Header" data-sentry-source-file="index.tsx">
            <Image height={40} width={40} src={Icon} alt="CharGen Icon" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            <Title data-sentry-element="Title" data-sentry-source-file="index.tsx">
                CharGen
                {userPremiumLevel !== UserPremiumType.NONE && <Styled.PremiumLevelIndicator>
                        {userPremiumLevel}
                    </Styled.PremiumLevelIndicator>}
            </Title>
            <Styled.AuthContainer data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <HeaderButtons isLoggedIn={isLoggedIn} userPremiumLevel={userPremiumLevel} userEmail={userEmail} gold={state.gold} isSidebar={false} data-sentry-element="HeaderButtons" data-sentry-source-file="index.tsx" />
            </Styled.AuthContainer>
            {isLoggedIn && <Styled.NotificationContainer>
                    <Notifications />
                </Styled.NotificationContainer>}
            <Sidebar data-sentry-element="Sidebar" data-sentry-source-file="index.tsx">
                <HeaderButtons isLoggedIn={isLoggedIn} userPremiumLevel={userPremiumLevel} userEmail={userEmail} username={username} isSidebar={true} data-sentry-element="HeaderButtons" data-sentry-source-file="index.tsx" />
                <GemCounterHeader userPremiumLevel={userPremiumLevel} userEmail={userEmail} data-sentry-element="GemCounterHeader" data-sentry-source-file="index.tsx" />
                {isLoggedIn && <Styled.ShowMenu>
                        <ProfileButton />
                        <SignOutButton />
                    </Styled.ShowMenu>}
            </Sidebar>
        </Styled.Header>;
};