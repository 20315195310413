import { createContext, Dispatch } from "react";

import { AppReducerAction, AppReducerState, UserPremiumType } from "types";

export const AppContext = createContext<{
    state: AppReducerState;
    dispatch: Dispatch<AppReducerAction>;
}>({
    state: {
        showPricingTable: false,
        showSubscriptionTable: false,
        gold: 0,
        cookieConsentGiven: false,
        isLoggedIn: false,
        showAuthModal: false,
        userPremiumLevel: UserPremiumType.NONE,
        userFreeGens: 10,
        isOverlayVisible: false,
        username: "",
        email: "",
        referralCode: "",
        gclid: "",
    },
    dispatch: () => {},
});
