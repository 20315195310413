export const colourScheme = {
    fullWhite: "#FFFFFF",
    white: "#E8EAF0",

    primaryDark: "#121425",
    primary: "#1A1C34",
    primaryLight: "#2C2E52",

    secondaryLight: "#7AA8E3", // Lightened for accent use
    secondary: "#4F80CC", // Slightly darker and richer blue
    secondaryDark: "#456CAB", // Darker secondary for accent areas

    successLight: "#61E0B1", // Adjusted to make more vibrant
    success: "#37C296", // Darkened slightly for contrast
    successDark: "#2CA478", // Darkened for hover or active states

    alertLight: "#F2D09A", // Keep this for light warnings
    alert: "#F0B853", // Adjusted for better contrast
    alertDark: "#E6A040", // Darkened for hover or active states

    errorLight: "#F48A87", // Keep this for lighter error states
    error: "#EB4D4B", // Slightly darker error color
    errorDark: "#D84341", // Darkened for hover or active states

    greyLight: "#B0B0B3", // Slightly darkened for consistency
    grey: "#7D7F83", // Darker grey for text or inactive elements
    greyDark: "#3B3D49", // Darkened for contrast and depth

    darkGradientStart: "#1B1C2D", // Slightly darker start for background gradient
    darkGradientEnd: "#101011", // Darker end for more depth in gradient

    secondaryGradientStart: "#2C3E50", // Keep the same for secondary gradient
    secondaryGradientEnd: "#4CA1AF", // Keep the same for secondary gradient
};

export const hexToRgba = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
