import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Small } from "utils/styling/typography.styled";

export const DropdownContainer = styled.div`
    position: relative;
`;

export const NewLabel = styled(Small)`
    vertical-align: top;
    font-size: 0.5rem !important;
    color: ${colourScheme.alert};

    ${device.xl} {
        font-size: 0.75rem !important;
    }
`;

export const PopoverItem = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${colourScheme.white};
    cursor: pointer;
    transition: transform 0.1s ease-in-out;

    & > span {
        white-space: nowrap;
    }

    &:hover {
        transform: scale(1.03);
    }
`;

export const ClickableDiv = styled.div`
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: ${colourScheme.primaryDark};
    color: ${colourScheme.white};
    border: 3px solid ${colourScheme.primaryLight};
    cursor: pointer;
    text-align: center;
    white-space: nowrap;

    &:hover {
        background-color: ${colourScheme.primary};
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colourScheme.secondary};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
`;
